<script>
import Social from '@/services/Social';

export default {
    components: {},
    props: {
        customer_id: String
    },
    data() {
        return {
            showLoader: false,
            modalData: {},
            customerInfo: {
                avatar: '',
                nickname: '',
                level: '',
                level_datetime: '',
                points: '',
                background: '',
                upvotes: '',
                progress: []
            },
            milestones: [],
            error: null
        };
    },
    computed: {
        hasCustomerData() {
            const { avatar, nickname, level, level_datetime, points, background, upvotes, progress } = this.customerInfo;
            return avatar || nickname || level || level_datetime || points || background || upvotes || progress.length > 0;
        }
    },
    mounted() {
        this.getSocialCustomer();
    },

    methods: {
    async getSocialCustomer() {
        this.showLoader = true;
        try {
            const response = await Social.getSocialCustomer(this.customer_id);
            if (response.data && response.data.data) {
                const { avatar, nickname, level, progress,level_datetime,points,background,upvotes} = response.data.data;
                this.customerInfo.avatar = avatar || '';
                this.customerInfo.nickname = nickname || '';
                this.customerInfo.background = background || '';
                this.customerInfo.level = level || '';
                this.customerInfo.level_datetime =level_datetime || '';
                this.customerInfo.points = points || '';
                this.customerInfo.upvotes = upvotes || '';
                this.customerInfo.progress = progress || [];
                await this.getMilestones();
            } else {
                this.error = "Invalid response format";
            }
        } catch (error) {
            this.error = error.response?.data?.error || "An error occurred";
        } finally {
            this.showLoader = false;
        }
    },
    async getMilestones() {
            try {
                const response = await Social.getSocialMilestones();
                if (response.data && response.data.data) {
                    this.milestones = response.data.data;
                    this.customerInfo.progress = this.customerInfo.progress.map(item => {
                        const milestone = this.milestones.find(m => m.id === item.milestone_id);
                        return {
                            ...item,
                            name: milestone ? milestone.name : 'Unknown'
                        };
                    });
                } else {
                    this.error = "Invalid response format for milestones";
                }
            } catch (error) {
                this.error = error.response?.data?.error || "An error occurred while fetching milestones";
            }
        }

  
}
};
</script>


<template>
    <div class="row mb-5">
        <div class="col-sm-12 col-md-12" v-if="!showLoader">
            <div class="row">
                        <div v-if="!hasCustomerData" class="text-center py-4">
                    <div class="col-12">
                        <i class="uil uil-folder-slash display-1"></i>
                        <h3 class="mt-2">No data found</h3>
                        <p>The user’s social profile data will be shown here when available</p>
                    </div>
                </div>
                <div v-else>
               
             <div class="row">
               <div class="col-md-4">
                 <div class="card bg-gradient-light h-100">
                   <div class="card-body d-grid">
                     <div>
                       <h5 class="text-muted mb-0 text-center"> <i class="uil uil-signal-alt-3 font-size-20 m-1"></i>Level</h5>

                     </div>
                     <h4 class="mt-3 align-self-end text-center">
                       <p style="font-size: 50px; min-height: 60px;">{{ customerInfo.level }}</p>
                     </h4>
                   </div>
                 </div>
               </div>
               <div class="col-md-4">
                 <div class="card bg-gradient-light h-100">
                   <div class="card-body d-grid">
                     <div>
                       <h5 class="text-muted mb-0 text-center"><i class="uil uil-star font-size-20 m-1"></i>Points</h5>
                     </div>
                     <h4 class="mt-3 align-self-end text-center">
                       <p style="font-size: 50px; min-height: 60px;">{{ customerInfo.points }}</p>
                     </h4>
                   </div>
                 </div>
               </div>
               <div class="col-md-4">
                 <div class="card bg-gradient-light h-100">
                   <div class="card-body d-grid">
                     <div>
                       <h5 class="text-muted mb-0 text-center">
                         <i class="uil uil-thumbs-up font-size-20 m-1"></i>Upvotes
                       </h5>
                     </div>
                     <h4 class="mt-3 align-self-end text-center">
                       <p style="font-size: 50px; min-height: 60px;">{{ customerInfo.upvotes }}</p> <!-- Added min-height -->
                     </h4>
                   </div>
                 </div>
               </div>

             </div>
                <div class="col-sm-12 col">
                    <div class="mb-2">
                        <p class="font-size-16"><span class="text-strong">As Of: </span>{{ customerInfo.level_datetime }}</p>
                    </div>
                </div>
               
                <div class="col-sm-12 col-md-4">
                    <div class="mb-2">
                        <p class="mb-1 text-strong">Nickname:</p>
                        <p class="font-size-16">{{ customerInfo.nickname }}</p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="mb-2">
                        <p class="mb-1 text-strong">Avatar:</p>
                        <p class="font-size-16">{{ customerInfo.avatar }}</p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="mb-2">
                        <p class="mb-1 text-strong">Background:</p>
                        <p class="font-size-16">{{ customerInfo.background }}</p>
                    </div>
                </div>
                    <div class="col-12" v-if="customerInfo.progress.length > 0">
                    <div class="mb-2">
                        <p class="mb-1 text-strong">Completed Milestones:</p>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Milestone Name</th>
                                    <th>Completed DateTime</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(progressItem, progressIndex) in customerInfo.progress" :key="progressIndex">
                                    <td>{{ progressItem.name }}</td>
                                    <td>{{ progressItem.completed_datetime }}</td>
                                    <td> {{ progressItem.customer_id }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div class="card-body d-flex align-items-center justify-content-center" v-else>
            <b-spinner large></b-spinner>
        </div>
    </div>
</template>
