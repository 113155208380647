<script>
    import Customers from '@/services/Customers';

    export default {
        props: ['userData', 'customer_id'],
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                company_name: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
            };
        },
        methods: {

            async editCustomer(){
                this.tryingToEdit = true;
                this.submitted = true;

                try {
                    await Customers.updateCustomer(this.customer_id,{
                        attributes:{
                          company_name: this.company_name
                        },
                        csrf_token: this.csrf_token
                    }).then((response) => {
                        this.successmsg("Change successfully saved");
                        this.refreshData()
                        this.closeModal();
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Failed to save changes")
                    }).finally(() => {
                        this.tryingToEdit = false;
                    })
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }

                this.tryingToEdit = false;
            },

            closeModal() {
                this.showModal = false;
            },

            refreshData() {
                this.$emit('onRefresh')
            },

            resetProps(){
                this.submitted = false
            },

            initialData(){
                this.company_name = this.userData.company_name
            }
        }
    };
</script>

<template>
    <b-modal @shown="initialData" @hidden="resetProps" v-model="showModal" id="edit_attributes" title="Edit Customer" title-class="font-18" centered>
        <form @submit.prevent="editCustomer">
            <b-form-group label="Company Name" label-for="formrow-company_name-input" class="mb-3">
                <b-form-input
                    v-model.trim="company_name"
                    type="text"
                    id="company_name"
                    aria-describedby="company_name-feedback"
                >
                </b-form-input>
            </b-form-group>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editCustomer" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Change</b-button>
        </template>
    </b-modal>
</template>