<script>

    import Customers from '@/services/Customers';
    import moment from "moment";


    export default {
        components: {  },
        data() {
            return {
                customerCollectibles:[],
                error: null,
                isBusy: false,
              
            }
        },
        computed: {
      
        },
        mounted() {
            this.getCustomerCollectibles()
        },
      
        methods: {

  formatDateTime(utcString) {
    if (!utcString) return 'N/A';
    const date = new Date(utcString);
    return date.toLocaleDateString(); 
  },

  formatDate(utcTimestamp) {
      const date = new Date(utcTimestamp * 1000);  
      return date.toLocaleString(); 
    },

    async getCustomerCollectibles() {

  this.isBusy = true;
  try {
    const response = await Customers.getCustomersCollectibles(this.$route.params.id);
    this.customerCollectibles = response.data.packs; 
  } catch (error) {
    this.error = error.response?.data?.error || 'An error occurred.';
  } finally {
    this.isBusy = false;
  }
}

}
    }
</script>

   <template>
    <div class="row">
      <div class="col-12">
        <div v-if="isBusy" class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
  
        <div v-else-if="customerCollectibles.length === 0" class="text-center my-2">
          <strong>No collectibles available.</strong>
        </div>
  
        <div v-else class="row">
          <div
            v-for="(pack, packIndex) in customerCollectibles"
            :key="packIndex"
            class="col-md-6 col-lg-4 mb-3"
          >
            <div class="card h-100">
             <img
                  v-if="pack.collectibles.length > 0 && pack.collectibles[0].image_url"
                  :src="pack.collectibles[0].image_url"
                  alt="Collectible Image"
                  class="card-img-top"
                />
                <div
                  v-else
                  class="d-flex justify-content-center align-items-center bg-light" style="height: 300px;"
                >
                </div>
            
              <div class="card-body">
                <h5 class="card-title">Pack ID: {{ pack.id }}</h5>
                <p class="card-title">
                  {{ pack.attributes.description }} - {{ formatDateTime(pack.attributes.date_time) }}
                </p>
                <p class="card-text">Collectibles: {{ pack.collectibles.length }}</p>
                <p class="card-text">
                  Claimed: {{ pack.claimed_utc ? formatDate(pack.claimed_utc) : 'No' }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  